* {
    font-family: "Averta CY", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.ant-upload-select {
    width: 80px !important;
    height: 80px !important;
    margin: 0 !important;
    font-size: 13px;
}

.discussion_block .ant-upload-list-item-container {
    margin: 0 10px 0 0 !important;
    width: 80px !important;
    height: 80px !important;
}

.alert_notification_block .ant-notification-notice-btn {
    width: 100%;
}