.ant-table-wrapper .ant-table-container table .ant-table-thead > tr > th {
    color: #858585;
    font-weight: 400;
    background: none;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    text-align: center;
}

.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
    background: none;
}

#pyrus-widget-container-element {
    margin-bottom: 200px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}
.ant-select-selection-item-content {
    width: 230px;
    white-space: normal;
    word-break: break-all;
}
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: black;
    cursor: default;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}